import { ENVIRONMENT_QUAL } from './qual';

export const environment = {
  ...ENVIRONMENT_QUAL,
  apiServer: 'https://paris.app.1life.com',
  myoneServer: 'https://paris.app.1life.com',
  sentry: {
    enabled: true,
    dsn: 'https://94fb2cb964527591835f763ad4493a4f@o4506221115408384.ingest.us.sentry.io/4506855554154496',
    environment: 'onelife-paris',
  },
  launchDarklyClientId: '604ff8d446ea680c8e6cfcd2',
  appSync: {
    apiUrl: 'https://wnenozxw6fdvhptccsjc7xumpm.appsync-api.us-east-1.amazonaws.com/graphql',
    region: 'us-east-1',
    enabled: true,
  },
  stripePublishableKey:
    'pk_test_51IBSBxFJ7pJZOcx4yemialNris29uF2BZ1jw7mOP2bxc4XXNEEtlhxnfR2MMCm6BO703s5fhEMkpLYkPxeGrIbvT002bsfbTPj',
};
